import React from "react";
import './allnewsletters.css'; // Import the CSS file for styling

export const AllNewsLetters = (props) => {
  const { data } = props;
  console.log('data', props);
  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div id="features" className="text-center section-title">
      <div className="container">
        <div className="col-md-12 text-center mt-90">
          <h2>News Letters</h2>
        </div>
        <div className="col-md-12">
          <div className="d-flex flex-wrap">
            {data.map((yearEntry, i) => (
              <div key={i} className="year-section">
                <h3 className="year-header">{yearEntry.year}</h3>
                {yearEntry.entries.map((entry, j) => (
                  <div key={j} className="newsletter-item">
                    <div className="details">
                        <a className="newsletter-link" href={entry.url} target="_blank" rel="noopener noreferrer">{entry.title}</a>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};